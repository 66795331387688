<template>
  <li class="tabs-navigation-item" role="presentation">
    <a :class="['item-link', { 'active': active }]" v-bind="$attrs" role="tab" :aria-selected="active ? 'true' : 'false'"><slot/></a>
  </li>
</template>

<script>
export default {
  name: 'TabsNavigationItem',
  inheritAttrs: false,
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
$tabs-navigation-item-link-padding-y: $component-padding-y !default;
$tabs-navigation-item-link-padding-x: $component-padding-x !default;
$tabs-navigation-item-link-bg: transparent !default;
$tabs-navigation-item-link-font-size: inherit !default;
$tabs-navigation-item-link-font-weight: $font-weight-bold !default;

$tabs-navigation-item-hover-link-bg: transparent !default;
$tabs-navigation-item-active-link-bg: $white !default;

$tabs-navigation-item-mobile-breakpoint: $mobile-breakpoint !default;

$tabs-navigation-item-mobile-link-padding-y: $component-padding-y * 0.6 !default;
$tabs-navigation-item-mobile-link-padding-x: $component-padding-x !default;

.tabs-navigation-item {
  .item-link {
    display: block;
    padding: $tabs-navigation-item-link-padding-y $tabs-navigation-item-link-padding-x;
    font-size: $tabs-navigation-item-link-font-size;
    font-weight: $tabs-navigation-item-link-font-weight;
    text-decoration: none;

    &:hover {
      background-color: $tabs-navigation-item-hover-link-bg;
    }

    &.active {
      background-color: $tabs-navigation-item-active-link-bg;
    }
  }

  &:first-child {
    .item-link {
      @include make-leaf($leaf-border-radius, 'left');
    }
  }

  @include media-breakpoint-down ($tabs-navigation-item-mobile-breakpoint) {
    .item-link {
      padding: $tabs-navigation-item-mobile-link-padding-y $tabs-navigation-item-mobile-link-padding-x;
    }
  }
}
</style>
