<template>
  <div class="tabs-navigation">
    <nav>
      <ul class="tabs-navigation-itemlist" role="tablist">
        <slot name="nav"/>
      </ul>

      <div class="tabs-navigation-append">
        <slot name="nav-append"/>
      </div>
    </nav>

    <div class="tabs-navigation-tab" role="tabpanel">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabsNavigation'
}
</script>

<style lang="scss">
$tabs-navigation-gap: $component-gap !default;

$tabs-navigation-itemslist-bg: $gray-200 !default;

$tabs-navigation-append-padding-y: 0 !default;
$tabs-navigation-append-padding-x: $component-padding-x !default;
$tabs-navigation-append-bg: $tabs-navigation-itemslist-bg !default;

$tabs-navigation-tab-padding-y: $component-padding-y !default;
$tabs-navigation-tab-padding-x: $component-padding-x !default;
$tabs-navigation-tab-bg: $white !default;

.tabs-navigation {
  margin-bottom: $tabs-navigation-gap;

  > nav {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  .tabs-navigation-itemlist {
    @include list-unstyled();
    @include make-leaf($leaf-border-radius, 'top');
    flex: 0 1 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    background-color: $tabs-navigation-itemslist-bg;
  }

  .tabs-navigation-append {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: $tabs-navigation-append-padding-y $tabs-navigation-append-padding-x;
    background-color: $tabs-navigation-append-bg;
  }

  .tabs-navigation-tab {
    @include make-leaf($leaf-border-radius, 'bottom');
    padding: $tabs-navigation-tab-padding-y $tabs-navigation-tab-padding-x;
    background-color: $tabs-navigation-tab-bg;

    > *:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
